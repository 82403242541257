// Hotwire
import * as Turbo from '@hotwired/turbo'
Turbo.start()
import "./controllers"

// Get Bootstrap's JavaScript to work
import * as bootstrap from "bootstrap"

// Styles
import "~/styles/custom_bootstrap.scss"
import "~/styles/calendar_table.css"
import "~/styles/application.css"
